<template>
  <DataTable
    :value="props.manifests"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
  >
    <Column header="N° Manifiesto" field="id" />
    <Column header="Courier" field="courier_name" />
    <Column header="N° Bultos" field="packages" />
    <Column header="Estado" field="status">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span
            v-if="data.status === 'confirmado'"
            class="confirmed"
            @click="openManifest(data.confirmation_file_name)"
            >{{ data.status }}</span
          >
          <span v-else>{{ data.status }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="created_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.created_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="confirmed_at"
      header="Fecha Confirmación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{
            data.confirmed_at ? formatDateTime(data.confirmed_at) : ""
          }}</span>
        </div>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div style="display: flex; justify-content: space-around">
          <i
            class="pi pi-upload actions"
            @click="openUploadForm(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-eye actions"
            @click="openManifest(data.file_name)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-trash actions"
            @click="onDeleteButtonPress(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-comments actions"
            @click="createTicketFromManifest(data.id)"
            style="color: #326eb5"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <UploadModal
    :visible="uploadModalVisible"
    :closeModal="closeUploadForm"
    :showToast="props.showToast"
    :selectedManifest="selectedManifest"
    :onUpload="props.loadManifests"
  />
</template>
<script setup>
import { defineProps, ref } from "vue";
import { createTicketFromManifest } from "../../services/support";
import { deleteManifest } from "../../api/manifest";
import UploadModal from "./UploadModal.vue";
import { formatDateTime } from "@/services/utils";

const uploadModalVisible = ref(false);
const selectedManifest = ref(null);
const sortField = ref(null);
const sortOrder = ref(null);

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};

const props = defineProps({
  manifests: Array,
  loadManifests: Function,
  showToast: Function,
});

const closeUploadForm = () => {
  uploadModalVisible.value = false;
};

const openUploadForm = (data) => {
  uploadModalVisible.value = true;
  selectedManifest.value = data;
};

const onDeleteButtonPress = async (data) => {
  try {
    await deleteManifest(data.id);
    props.loadManifests();
    props.showToast("success", "Manifiesto eliminado con éxito");
  } catch (err) {
    props.showToast("error", "Error al eliminar el manifiesto");
    console.error(err);
  }
};

const openManifest = (fileName) => {
  const baseUrl = process.env.VUE_APP_FILES_URL;
  window.open(`${baseUrl}/${fileName}`, "_blank");
};
</script>

<style scoped>
.actions {
  color: "#326EB5";
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.confirmed {
  cursor: pointer;
  color: #2b45a2;
}
</style>
