<template>
  <div class="layout-dashboard">
    <Toast />
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card card-w-title statistics">
          <h5>Manifiestos</h5>
          <Filters
            v-if="customer.id"
            :setSerchedText="setSerchedText"
            :customerId="customer.id"
            :setStartDate="setStartDate"
            :setEndDate="setEndDate"
            :setSelectedStatus="setSelectedStatus"
            :setSelectedCourier="setSelectedCourier"
          />
          <ProgressSpinner
            style="
              width: 50px;
              height: 50px;
              margin-top: 20px;
              margin-left: 40%;
            "
            class="spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
            v-if="loading"
          />

          <List
            v-else
            :manifests="filteredManifests"
            :loadManifests="startComponent"
            :showToast="showToast"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { readUserData } from "@/services/user";
import { ref, computed } from "vue";
import { findByUser } from "@/api/customer";
import { useToast } from "primevue/usetoast";
import ProgressSpinner from "primevue/progressspinner";
import { getManifests } from "@/api/manifest";
import Filters from "./Filters.vue";
import List from "./List.vue";

const user = readUserData();
let customer = ref({});
const toast = useToast();
const loading = ref(false);
const manifests = ref([]);
const searchedText = ref("");
const startDate = ref("");
const endDate = ref("");
const selectedStatus = ref(null);
const selectedCourier = ref("");

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const loadManifests = async () => {
  const { data } = await getManifests(customer.value.id);
  manifests.value = data.data;
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const startComponent = async () => {
  loading.value = true;
  await getCustomerData();
  if (!customer.value.id) {
    loading.value = false;
    return;
  }
  await loadManifests();
  loading.value = false;
};

const setSerchedText = (text) => {
  searchedText.value = text;
};

const setStartDate = (date) => {
  startDate.value = date;
};

const setEndDate = (date) => {
  endDate.value = date;
};

const setSelectedStatus = (status) => {
  selectedStatus.value = status;
};

const setSelectedCourier = (courier) => {
  selectedCourier.value = courier;
};

const filteredManifests = computed(() => {
  return manifests.value.filter((manifest) => {
    const text = searchedText.value.toLowerCase();
    const status = selectedStatus.value;
    const courier = selectedCourier.value;
    const startDateFilter = new Date(startDate.value);
    const endDateFilter = new Date(endDate.value);
    return (
      (manifest.status.toLowerCase().includes(text) ||
        manifest.courier_name.toLowerCase().includes(text) ||
        manifest.id.toLowerCase().includes(text)) &&
      (status === null || manifest.status === status.toLowerCase()) &&
      (courier === "" || manifest.courier_name === courier) &&
      (startDate.value === "" ||
        new Date(manifest.created_at) >= startDateFilter) &&
      (endDate.value === "" || new Date(manifest.created_at) <= endDateFilter)
    );
  });
});

startComponent();
</script>

<style scoped></style>
