<template>
  <Dialog
    :key="props.visible"
    :visible="props.visible"
    modal
    :closable="true"
    header="Subir confirmación manifiesto"
    @update:visible="props.closeModal"
  >
    <div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
          <label for="file">Archivo</label>
          <input type="file" id="file" name="file" @change="onUpload" />
        </div>
        <br />
        <Button label="Subir" icon="pi pi-upload" @click="save" />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { uploadFile } from "@/api/files";
import { saveFileName } from "@/api/manifest";

const props = defineProps({
  visible: Boolean,
  closeModal: Function,
  selectedManifest: Object,
  showToast: Function,
  onUpload: Function,
});

const file = ref(null);

const onUpload = (event) => {
  file.value = event.target.files[0];
};

const save = async () => {
  try {
    await uploadFile(file.value, file.value.name, "manifests");
    await saveFileName(props.selectedManifest.id, file.value.name, true);
    props.closeModal();
    props.showToast("success", "Listo!", "Manifiesto subido correctamente");
    props.onUpload();
  } catch (error) {
    console.log(error);
    props.showToast(
      "error",
      "Error",
      "Ha ocurrido un error al subir el manifiesto"
    );
  }
};
</script>
